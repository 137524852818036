import React from "react";
import styled from "styled-components";
import Header from "../header/header";
import Footer from "../footer/footer";

const ErrorContainer = styled.div`
    color: #a30000;
    background: #000000;
    font-size: calc(1rem + 2vmin);
    text-align: center;
    width: 100vw;
    font-family: arial;
    display: flex;
    margin: 15vh auto;
    justify-content: center;
    align-items: center;
`;

const ErrorPage = () => {
    return (
        <>
            <Header />
            <ErrorContainer>
                Sorry, but that page is not found. Please click on the link in the header.
            </ErrorContainer>
            <Footer />
        </>
    )
}

export default ErrorPage;
