import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.h1`
  background: inherit;
  color: inherit;
  font-size: calc(6rem + 3vmin);
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  text-align: center;
`;

const HeaderLink = styled.a`
    background: inherit;
    color: #dedede;
    text-decoration: none;
    &:hover {
        color: #bcbcbc;
    }
`

const Header = () => {
    return (
        <HeaderContainer>
            <HeaderLink href="/">
                Grave
            </HeaderLink>
        </HeaderContainer>
    )
};

export default Header;