import React from 'react';
import styled from 'styled-components';
import Links from '../../interfaces/links';
import { device } from '../media-query/media-query';

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  backface-visibility: hidden;
  transition: transform 0.5s ease;
`;

const CardBack = styled(CardFront)`
  transform: rotateY(180deg);
  background-color: #a30000;
  color: #000000;
  border: 0.25rem solid #000000;
  border-radius: 1rem;
`;

const CardContainer = styled.button`
  width: 30vw;
  height: 10dvh;
  perspective: 1000px;
  background-color: #000000;
  color: #a30000;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  border: 0.25rem solid #3c0000;
  border-radius: 1rem;

  &:hover ${CardFront} {
    transform: rotateY(180deg);
  }

  &:hover ${CardBack} {
    transform: rotateY(0deg);
  }

  @media ${device.sm} {
    width: 90dvw;
  }
`;

const CardText = styled.h3`
  font-size: 1.2rem;
`;

const LinkCard = ({ links }: { links: Links }) => {
  const handleClick = () => {
    if (links.href && links.isOver18) {
      const confirmed = window.confirm('This content may only be viewed by users 18 years or older. Are you 18 or older?');
      if (confirmed) {
        window.open(links.href, '_blank');
      }
    } else if (links.href && !links.isOver18) {
      window.open(links.href, '_blank');
    }
  };

  return (
    <CardContainer onClick={handleClick}>
      <CardFront>
        <CardText>{links.text}</CardText>
      </CardFront>
      <CardBack>
        <CardText>{links.text}</CardText>
      </CardBack>
    </CardContainer>
  );
};

export default LinkCard;
