import styled from "styled-components";
import Links from "../../interfaces/links";

const AboutDiv = styled.div`
    width: 100vw;
    text-align: center;
`;

const AboutLink = styled.button`
    font-size: inherit;
    background: transparent;
    border: none;
    cursor: pointer;
`;

const AboutCard = ({ links }: { links: Links }) => {
    const handleClick = () => {
        if (links.href && links.isOver18) {
          const confirmed = window.confirm('This content may only be viewed by users 18 years or older. Are you 18 or older?');
          if (confirmed) {
            window.open(links.href, '_blank');
          }
        }
      };
    return (
        <AboutDiv>
            Welcome to my Grave!!
            <br />
            Hehe Welcome to my site!!! I am your well endowed slutty gaming dream woman.
            <br />
            Come play with me and we can make some wild wishes come true... maybe
            <br />
            <AboutLink onClick={handleClick}>
                &#x1F608;
            </AboutLink>
        </AboutDiv>
    )
}

export default AboutCard;