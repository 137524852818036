import React from "react";
import styled from "styled-components";
import { device } from "../media-query/media-query";

const AppFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  flex-flow: column;
  color: #a30000;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media ${device.sm} {
    flex-flow: column;
  }
`;

const InternalLinks = styled.a`
  color: #a30000;
  text-decoration: none;
  &:hover {
    color: #FFFFFF;
  }
`;

const HideSmall = styled.div`
  @media ${device.sm} {
    display: none;
  }
`;

const Footer = () => {
    return (
        <AppFooter>
            &copy; {new Date().getFullYear()}
            <br />
            <FooterLinks>
                <InternalLinks href="/privacy">Privacy Policy</InternalLinks>
                <HideSmall>
                  &nbsp;
                  &#124;
                  &nbsp;
                </HideSmall>
                <InternalLinks href="/terms">Terms and Conditions</InternalLinks>
            </FooterLinks>
        </AppFooter>
    )
};

export default Footer;
