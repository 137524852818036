import React from 'react';
import styled from 'styled-components';
import './App.css';
import AboutCard from './components/about/about-card';
import Links from './interfaces/links';
import LinkCard from './components/link/link-card';

const AppDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  flex-flow: column;
`;

const AppRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2dvw;
  margin-bottom: 5dvh;
`;

const linkData: Links[] = [
  {
    href: "https://fansly.com/GravePuppett/",
    text: "Fansly 18+ Only",
    isOver18: true
  },
  {
    href: "https://www.tiktok.com/@gravepuppett",
    text: "TikTok",
    isOver18: false
  },
  {
    href: "https://www.twitch.tv/gravepuppett",
    text: "Twitch",
    isOver18: false
  },
  {
    href: "https://twitter.com/GravePuppett",
    text: "X",
    isOver18: false
  },
  {
    href: "https://www.instagram.com/gravepuppet/",
    text: "Instagram",
    isOver18: false
  },
  {
    href: "https://steamcommunity.com/id/inurgravve/",
    text: "Steam",
    isOver18: false
  },
  {
    href: "https://www.youtube.com/@Gravepuppet",
    text: "YouTube",
    isOver18: false
  }
];

function App() {
  return (
    <AppDiv>
      <AboutCard links={linkData[0]}/>
      <AppRow>
        {linkData && linkData.map(link => {
          return <LinkCard links={link}></LinkCard>;
        })}
      </AppRow>
    </AppDiv>
  );
}

export default App;
