import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import reportWebVitals from './reportWebVitals';
import TermsAndConditions from './components/terms-and-conditions/terms-and-conditions';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import ErrorPage from './components/error/error';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function Layout() {
  return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />
      },
      {
        path: "/terms",
        element: <TermsAndConditions />
      }
    ]
}]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
